import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Stack,
  Grid,
  Stepper,
  Container,
  List,
  Image,
  Anchor,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

export const RegistrationForm = (props: PaperProps) => {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const [registrationDisabled, setRegistrationDisabled] = useState(false);
  const navigate = useNavigate();
  const api = useAxios();

  const submitUserRegistration = (
    order_id: string,
    email: string,
    username: string,
    password: string
  ) => {
    if (!registrationDisabled) {
      setRegistrationDisabled(true);

      api
        .post("/register_account", {
          order_id: order_id,
          email: email,
          username: username,
          password: password,
        })
        .then((resp) => {
          if (resp.data["success"]) {
            nextStep();
          } else {
            notifications.show({
              title: "Error while registering",
              color: "red",
              message: resp.data["detail"],
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          notifications.show({
            title: "Registration Error",
            color: "red",
            message:
              "An error has occured when trying to register. Please try again or contact us if the issue persists.",
            autoClose: 3000,
          });
        });

      setTimeout(() => setRegistrationDisabled(false), 3000);
    }
  };

  const form = useForm({
    initialValues: {
      order_id: "",
      email: "",
      username: "",
      password: "",
      password_confirm: "",
    },

    validate: {
      order_id: (value) => {
        let parts = value.split("-");
        return (parts[0] === "SHMMR" &&
          parts[1].length === 4 &&
          !isNaN(Number(parts[1]))) ||
          value.length === 0
          ? null
          : true;
      },
      email: (value) => (/^\S+@\S+$/.test(value) ? null : true),
      username: (value) => {
        return /^[a-zA-Z0-9-_]+$/.test(value) &&
          value.length >= 3 &&
          value.length <= 16
          ? null
          : true;
      },
      password: (value) => {
        return value.length >= 8 && value.length <= 127 ? null : true;
      },
      password_confirm: (value, values) =>
        value === values.password ? null : true,
    },
  });

  return (
    <>
      <Container>
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Step 1" description="Device Type">
            <Group position="center">
              <Stack>
                <Text size="xl">
                  Welcome to Shmmr! We will get you set up in a few easy steps.
                </Text>
                <Text size="lg" mt={20}>
                  First, which one of these options describes you?
                </Text>
                <Button
                  size="md"
                  color="gray"
                  onClick={() => {
                    setActive(1);
                  }}
                >
                  I want to make my own Shmmr Device
                </Button>
                <Button
                  size="lg"
                  onClick={() =>
                    window.open("https://shop.shmmr.app", "_blank")
                  }
                >
                  I NEED to purchase a Shmmr Device
                </Button>
                <Button
                  size="lg"
                  onClick={() => {
                    setActive(2);
                  }}
                  color="green"
                >
                  I ALREADY purchased a Shmmr Device
                </Button>
              </Stack>
            </Group>
          </Stepper.Step>
          <Stepper.Step label="Step 2" description="Device Instructions">
            <Group>
              <Stack>
                <Text size="xl">How To Create Your Own Shmmr Device</Text>
                <List listStyleType="none">
                  <List.Item>
                    <Text fz="lg">Materials</Text>
                    <List withPadding listStyleType="disc">
                      <List.Item>
                        Arduino-Compatible Device (UNO, NANO, RP2040)
                      </List.Item>
                      <List.Item>Breadboard</List.Item>
                      <List.Item>Jumper Wires</List.Item>
                      <List.Item>940mm IR LED</List.Item>
                      <List.Item>
                        BC3337 Transistor (Optional, Depending on Board)
                      </List.Item>
                      <List.Item>
                        470 Ohm Resistor (Optional, Depending on Board)
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fz="lg" mt={10}>
                      Assembly
                    </Text>
                    <List withPadding listStyleType="none">
                      <List.Item>
                        <Image
                          maw={300}
                          mx="auto"
                          radius="md"
                          src="https://beta.shmmr.app/static/img/shmmrcircuit2.png"
                          alt="Circuit diagram"
                        />
                      </List.Item>
                      <Image
                        maw={300}
                        mx="auto"
                        radius="md"
                        src="https://beta.shmmr.app/static/img/shmmrcircuit1.png"
                        alt="Circuit diagram"
                      />
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fz="lg" mt={10}>
                      Assembly
                    </Text>
                    <List withPadding listStyleType="disc">
                      <List.Item>
                        <Text>Assemble the circuit as shown above</Text>
                      </List.Item>
                      <List.Item>
                        <Text>
                          On some boards, you might be able to hook up the IR
                          LED directly to ground and Pin 3 without issues. Use
                          at your discretion
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text>
                          Load the Shmmr firmware code on your Arduino IDE
                        </Text>
                        <Text>
                          <Anchor
                            href="https://beta.shmmr.app/static/firmware/ShmmrTransmitterFirmware_v1_2.zip"
                            target="_blank"
                          >
                            Click Here to Download Shmmr Firmware
                          </Anchor>
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text>
                          Configure the Pin settings to match your setup
                        </Text>
                      </List.Item>
                      <List.Item>
                        <Text>Flash your arduino device.</Text>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Button
                      size="md"
                      onClick={() => {
                        setActive(2);
                      }}
                      mt={10}
                    >
                      Once device is complete, Click here to register for an
                      account
                    </Button>
                  </List.Item>
                </List>
              </Stack>
            </Group>
          </Stepper.Step>
          <Stepper.Step label="Step 3" description="Account Creation">
            <Grid>
              <Grid.Col span={6} offset={3}>
                <Paper radius="md" p="xl" withBorder {...props}>
                  <Text size="lg" weight={500}>
                    Register
                  </Text>
                  <Divider
                    label="Order Details"
                    labelPosition="center"
                    my="lg"
                  />
                  <form
                    onSubmit={form.onSubmit(async (values) => {
                      submitUserRegistration(
                        values.order_id,
                        values.email,
                        values.username,
                        values.password
                      );
                    })}
                  >
                    <Stack>
                      <TextInput
                        label="Order Number (Leave Blank for DIY)"
                        placeholder="SHMMR-1234"
                        value={form.values.order_id}
                        onChange={(event) =>
                          form.setFieldValue(
                            "order_id",
                            event.currentTarget.value.toUpperCase()
                          )
                        }
                        error={
                          form.errors.order_id &&
                          "Invalid order number. Please enter the entire number SHMMR-XXXX or Blank for DIY"
                        }
                        radius="md"
                      />
                      <TextInput
                        required
                        label="Email Address"
                        placeholder="email@example.com"
                        value={form.values.email}
                        onChange={(event) =>
                          form.setFieldValue(
                            "email",
                            event.currentTarget.value.toLowerCase()
                          )
                        }
                        error={form.errors.email && "Invalid email address."}
                        radius="md"
                      />
                      <Divider
                        label="User Credentials"
                        labelPosition="center"
                      />
                      <TextInput
                        required
                        label="Username"
                        placeholder="Desired Username"
                        value={form.values.username}
                        onChange={(event) =>
                          form.setFieldValue(
                            "username",
                            event.currentTarget.value
                          )
                        }
                        error={
                          form.errors.username &&
                          "Usernames must be at 3-16 characters and contain only numbers, letters, underscore, and dashes"
                        }
                        radius="md"
                      />

                      <PasswordInput
                        required
                        label="Password"
                        value={form.values.password}
                        onChange={(event) =>
                          form.setFieldValue(
                            "password",
                            event.currentTarget.value
                          )
                        }
                        error={
                          form.errors.password &&
                          "Password must be at least 8 characters."
                        }
                        radius="md"
                      />
                      <PasswordInput
                        required
                        label="Confirm Password"
                        value={form.values.password_confirm}
                        onChange={(event) =>
                          form.setFieldValue(
                            "password_confirm",
                            event.currentTarget.value
                          )
                        }
                        error={
                          form.errors.password_confirm &&
                          "Password confirmation does not match"
                        }
                        radius="md"
                      />
                    </Stack>
                    <Group position="right" mt="xl">
                      <Button
                        type="submit"
                        radius="xl"
                        disabled={registrationDisabled}
                      >
                        Create Account
                      </Button>
                    </Group>
                  </form>
                </Paper>
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Completed>
            <Group position="center">
              <Stack>
                <Text size="xl">Registration Complete!</Text>
                <Text size="lg" mt={20}>
                  Your account has been created.
                </Text>
                <Button size="lg" onClick={() => navigate("/login")}>
                  Click Here to Log In
                </Button>
              </Stack>
            </Group>
          </Stepper.Completed>
        </Stepper>
      </Container>

      <Group position="center">
        <Group position="center" mt={10}>
          <Text size="sm">
            Already have an account?
            <Button
              variant="subtle"
              compact
              onClick={() => {
                navigate("/login");
              }}
            >
              Click here to log in!
            </Button>
          </Text>
        </Group>
      </Group>
    </>
  );
};
