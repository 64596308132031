import { Text, Modal, Button, Group, NumberInput } from "@mantine/core";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { useState } from "react";
import { TimelineEntry } from "../types/project";
import { EffectSelector } from "./EffectSelector";

interface EntryEditorProps {
  opened: boolean;
  entry: TimelineEntry;
  timestamp: number;
  onEdit?: (entry: TimelineEntry) => void;
  onClose?: () => void;
}

export const EntryEditorModal = (props: EntryEditorProps) => {
  const [timestampValue, setTimestampValue] = useState(props.entry.timestamp);
  if (!props.entry) return null;
  return (
    <>
      <Modal
        opened={props.opened}
        onClose={props.onClose ? props.onClose : () => {}}
        title="Edit Timeline Entry"
        size={"xl"}
      >
        <Text size="md" mt={10}>
          Modify Time
        </Text>
        <Group position="center">
          <NumberInput
            value={timestampValue}
            onChange={(evt) => setTimestampValue(evt !== "" ? evt : 0)}
            precision={2}
            min={0}
            step={0.01}
          />
          <Button
            onClick={() => {
              setTimestampValue(props.timestamp);
            }}
          >
            Use Current Video Time
          </Button>
        </Group>
        <Text size="md" mt={10}>
          Modify Effect
        </Text>
        <Group position="left">
          <EffectSelector
            testable={true}
            selectedEffect={props.entry.effect}
            selectedModifier={props.entry.modifier}
            label="Edit Entry"
            onSelect={(selectedEffect, selectedModifier) => {
              props.onEdit &&
                props.onEdit({
                  ref_id: props.entry.ref_id,
                  timestamp: timestampValue,
                  effect: selectedEffect.id,
                  effect_name: selectedEffect.name,
                  modifier: selectedModifier?.id,
                  modifier_name: selectedModifier?.name,
                } as TimelineEntry);
            }}
          />
        </Group>
      </Modal>
    </>
  );
};
