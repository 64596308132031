import { atom } from "recoil";
import { v4 as uuidv4 } from "uuid";

export interface NavStatus {
  showConnector: boolean;
  inEditor: boolean;
  title?: string;
  sessionId: string;
}

const navAtom = atom<NavStatus>({
  key: "nav",
  default: {
    showConnector: false,
    inEditor: false,
    sessionId: uuidv4(),
  },
});

export { navAtom };
