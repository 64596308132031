import { Button, Group, Select, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { Flask, Plus } from "tabler-icons-react";
import { useDevices } from "../hooks/useDevices";
import { Device, DeviceEffect } from "../types/device";

export interface EffectSelectorProps {
  testable?: boolean;
  size?: string;
  label?: string;
  testingDisabled?: boolean;
  previewMode?: boolean;
  selectedEffect?: string;
  selectedModifier?: string;
  dropdownPosition?: "bottom" | "top" | "flip";

  onSelect?: (
    selectedEffect: DeviceEffect,
    selectedModifier?: DeviceEffect
  ) => void;
  onTest?: (
    selectedEffect: DeviceEffect,
    selectedModifier?: DeviceEffect
  ) => void;
}

export const EffectSelector = (props: EffectSelectorProps) => {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [selectedEffect, setSelectedEffect] = useState<DeviceEffect | null>(
    null
  );
  const [selectedModifier, setSelectedModifier] = useState<DeviceEffect | null>(
    null
  );
  const [devices, deviceEffects] = useDevices(selectedDevice?.id);

  useEffect(() => {
    if (props.selectedEffect) {
      setSelectedDevice(devices[0]);
      setSelectedEffect(
        deviceEffects.filter((eff) => eff.id === props.selectedEffect)[0]
      );
      if (props.selectedModifier) {
        setSelectedModifier(
          deviceEffects.filter((eff) => eff.id === props.selectedModifier)[0]
        );
      }
    }
  }, [deviceEffects, props.selectedEffect, props.selectedModifier]);

  return (
    <Group position="center">
      <Select
        size={props.size}
        placeholder="Select Device"
        searchable={true}
        dropdownPosition={props.dropdownPosition}
        withinPortal
        data={devices.map((device) => {
          return {
            value: device.id,
            label: device.name,
          };
        })}
        defaultValue={devices.length > 0 ? devices[0].id : undefined}
        onChange={(val) => {
          setSelectedDevice(devices.filter((dev) => val === dev.id)[0]);
        }}
      />
      <Select
        size={props.size}
        placeholder="Select Effect"
        dropdownPosition={props.dropdownPosition}
        searchable={true}
        disabled={!selectedDevice}
        withinPortal
        defaultValue={props.selectedEffect}
        data={deviceEffects
          .filter((effect) => !effect.modifier)
          .map((effect) => {
            return {
              value: effect.id,
              label: effect.name,
            };
          })}
        onChange={(val) =>
          setSelectedEffect(deviceEffects.filter((eff) => val === eff.id)[0])
        }
      />
      <Select
        size={props.size}
        allowDeselect
        placeholder="Select Modifier"
        dropdownPosition={props.dropdownPosition}
        searchable={true}
        disabled={!selectedDevice || !selectedEffect}
        withinPortal
        defaultValue={props.selectedModifier}
        data={deviceEffects
          .filter((effect) => effect.modifier)
          .map((effect) => {
            return {
              value: effect.id,
              label: effect.name,
            };
          })}
        onChange={(val) =>
          setSelectedModifier(deviceEffects.filter((eff) => val === eff.id)[0])
        }
      />

      {props.testable && (
        <Tooltip
          label={
            props.testingDisabled
              ? "Testing is disabled while device is not connected"
              : "Send selected signal to device to preview"
          }
        >
          <Button
            size={props.size}
            color="cyan"
            disabled={
              !selectedDevice || !selectedEffect || props.testingDisabled
            }
            leftIcon={<Flask size="0.8rem" />}
            onClick={() => {
              selectedEffect &&
                props.onTest &&
                props.onTest(selectedEffect, selectedModifier ?? undefined);
            }}
          >
            Test
          </Button>
        </Tooltip>
      )}
      <Button
        color={props.previewMode ? undefined : "green"}
        size={props.size}
        disabled={!selectedDevice || !selectedEffect}
        leftIcon={
          props.previewMode ? <Flask size="0.8rem" /> : <Plus size="0.8rem" />
        }
        onClick={() => {
          selectedEffect &&
            props.onSelect &&
            props.onSelect(selectedEffect, selectedModifier ?? undefined);
        }}
      >
        {props.previewMode ? "Preview" : props.label ? props.label : "Add"}
      </Button>
    </Group>
  );
};
