import * as React from "react";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Stack,
  Grid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import { Typography } from "tabler-icons-react";

export function LoginForm(props: PaperProps) {
  const { user, loginUser } = React.useContext(AuthContext);
  const [loginDisabled, setLoginDisabled] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    // redirect to home if already logged in
    if (user) navigate("/projects");
  }, [user]);

  const submitUserLogin = (username: string, password: string) => {
    if (!loginDisabled) {
      loginUser(username, password);
      setTimeout(() => setLoginDisabled(false), 1000);
    }
  };

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  });

  return (
    <Grid>
      <Grid.Col span={4} offset={4}>
        <Paper radius="md" p="xl" withBorder {...props}>
          <Text size="lg" weight={500}>
            Welcome Back!
          </Text>
          <Divider label="Log In" labelPosition="center" my="lg" />
          <form
            onSubmit={form.onSubmit(async (values) => {
              setLoginDisabled(true);
              submitUserLogin(values.username, values.password);
            })}
          >
            <Stack>
              <TextInput
                required
                label="Username"
                placeholder="Your Username"
                value={form.values.username}
                onChange={(event) =>
                  form.setFieldValue("username", event.currentTarget.value)
                }
                error={form.errors.username && "Invalid email"}
                radius="md"
              />

              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Password should include at least 6 characters"
                }
                radius="md"
              />
            </Stack>
            <Group position="right" mt="xl">
              <Button type="submit" radius="xl" disabled={loginDisabled}>
                Login
              </Button>
            </Group>
          </form>
          <Group position="center" mt={10}>
            <Text size="sm">
              No account?
              <Button
                variant="subtle"
                compact
                onClick={() => {
                  navigate("/register");
                }}
              >
                Click here to register!
              </Button>
            </Text>
          </Group>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}
