import { Button, Group, Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import useAxios from "../hooks/useAxios";
import { navAtom } from "../state";

export interface SessionWarningModalProps {
  project_id: string;
}

export const SessionWarningModal = (props: SessionWarningModalProps) => {
  const nav = useRecoilValue(navAtom);
  const [opened, setOpened] = useState(false);
  const api = useAxios();

  useEffect(() => {
    if (props.project_id === "") {
      return;
    }

    api.post(`/projects/${props.project_id}/session/update`, {
      session_id: nav.sessionId,
    });

    const interval = setInterval(() => {
      api.get(`/projects/${props.project_id}/session`).then((resp) => {
        try {
          if (
            resp.data["latest_session"] !== nav.sessionId &&
            resp.data["override"] === false
          ) {
            setOpened(true);
          }
        } catch (err) {
          console.error(err);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Modal.Root opened={opened} onClose={() => {}} centered>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>New Session Detected</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            It looks like you have opened up this project on another window.
            Please refresh the page to update or switch to the active tab.
            <Group mt={5} position="right">
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            </Group>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
