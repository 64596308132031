import { Button, Divider, Group, Table } from "@mantine/core";
import useAxios from "../hooks/useAxios";
import { Trash } from "tabler-icons-react";
import { notifications } from "@mantine/notifications";
import { useUserPresets } from "../hooks/useUserPresets";
import { EffectSelector } from "./EffectSelector";
import { DeviceEffect } from "../types/device";
import { bitsToSerialString, createBitCode, sendToSerial } from "../App";
import { useProjectEffects } from "../hooks/useProjectEffects";

export interface PresetModalProps {
  project_id: string;
  serialPort: any;
}

export const PresetModal = ({ project_id, serialPort }: PresetModalProps) => {
  const api = useAxios();
  const deviceMapping = useProjectEffects(project_id);
  const [presets, setPresetRevision] = useUserPresets(project_id);

  const createPreset = (effect: DeviceEffect, modifier?: DeviceEffect) => {
    api
      .post(`projects/${project_id}/presets/create`, {
        effect: effect.id,
        modifier: modifier?.id ?? null,
      })
      .then(() => {
        // TODO: Do something with response???
        setPresetRevision(Date.now());
      })
      .catch(() => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while creating preset",
          autoClose: 3000,
        });
      });
  };

  const deletePreset = (preset_id: string) => {
    api
      .delete(`projects/${project_id}/presets/${preset_id}/delete`)
      .then((deleteResponse) => {
        setPresetRevision(Date.now());
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while deleting preset",
          autoClose: 3000,
        });
      });
  };

  return (
    <Group position="center">
      <EffectSelector
        testable={true}
        testingDisabled={serialPort === undefined}
        onSelect={createPreset}
        onTest={async (selectedEffect, selectedModifier) => {
          if (serialPort === undefined) return;
          await sendToSerial(
            serialPort,
            bitsToSerialString(
              createBitCode(
                deviceMapping[selectedEffect.id],
                selectedModifier ? deviceMapping[selectedModifier!.id] : []
              )
            )
          );
        }}
      />
      <Divider mt="md" mb="md" />
      <Table verticalSpacing="xs">
        <thead>
          <tr>
            <th>Effect</th>
            <th>Modifier</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {presets.map((preset) => {
            return (
              <tr key={preset.id}>
                <td>{preset.effect_name}</td>
                <td>{preset.modifier_name}</td>
                <td>
                  <Button
                    size="xs"
                    variant="subtle"
                    onClick={() => {
                      deletePreset(preset.id);
                    }}
                  >
                    <Trash />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Group>
  );
};
