import { atom } from "recoil";
import { Device, DeviceEffect } from "../types/device";

const deviceAtom = atom<Device[]>({
  key: "device",
  default: [],
});

const deviceEffectAtom = atom<DeviceEffect[]>({
  key: "deviceeffect",
  default: [],
});

const deviceMappingAtom = atom<Record<string, number[]>>({
  key: "devicemapping",
  default: {},
});

export { deviceAtom, deviceEffectAtom, deviceMappingAtom };
