import { Navigate } from "react-router-dom";
import { ReactElement, useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import { notifications } from "@mantine/notifications";

export interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps): ReactElement => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!auth.user) {
      notifications.show({
        title: "Log In Required",
        color: "red",
        message: "You must be logged in to access that resource",
        autoClose: 3000,
      });
    }
  }, [auth.user]);

  return auth.user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
