import { MantineProvider } from "@mantine/core";
import { AppShell } from "@mantine/core";
import Editor from "./Editor";

import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import { LoginForm } from "./Login";
import { Notifications } from "@mantine/notifications";
import { HeaderContent } from "./components/layout/HeaderContent";
import { FooterContent } from "./components/layout/FooterContent";
import Browser from "./Browser";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Projects from "./components/pages/Projects";
import { Home } from "./components/pages/Home";
import { RegistrationForm } from "./components/pages/RegistrationForm";
import Browse from "./components/pages/Browse";

export const createBitCode = (
  effect: number[],
  modifier: number[]
): number[] => {
  return effect.concat(modifier);
};

export const bitsToRunLengthPulses = (bitList: number[]) => {
  let runLengths: number[] = [];

  let currentValue = 0;

  bitList.forEach((n: number) => {
    if (runLengths.length === 0) {
      runLengths.push(1);
      currentValue = n;
    } else {
      if (n === currentValue) {
        runLengths[runLengths.length - 1]++;
      } else {
        runLengths.push(1);
        currentValue = n;
      }
    }
  });

  return runLengths;
};

export const lengthPulsesToSerialString = (lengthPulses: number[]): string => {
  return `#4[${lengthPulses.length}]${lengthPulses.join("-")},`;
};

export const bitsToSerialString = (bitList: number[]): string => {
  return lengthPulsesToSerialString(bitsToRunLengthPulses(bitList));
};

export const connectSerial = (setSerialPort: React.Dispatch<any>) => {
  navigator.serial
    .requestPort({ filters: [] })
    .then(async (port: any) => {
      // Connect to `port` or add it to the list of available ports.
      await port.open({ baudRate: 115200 });
      setSerialPort(port);
    })
    .catch((e) => {
      // The user didn't select a port.
      alert(e);
    });
};

export const disconnectSerial = (serialPort: any, setSerialPort: any) => {
  serialPort.close();
  setSerialPort(undefined);
};

export const sendToSerial = async (serialPort: any, serialString: string) => {
  if (serialPort === undefined) return;
  if (serialPort.writable === undefined) return;
  if (serialPort.writable.locked) return;

  try {
    let enc = new TextEncoder();
    const writer = serialPort.writable.getWriter();
    await writer.write(enc.encode(serialString));
    writer.releaseLock();
  } catch (err) {
    alert(err);
  }
};

/*

export const testSerial = async (
  writable: any,
  effectValue: string,
  tailValue?: string
) => {
  let ss = bitsToSerialString(
    createBitCode(
      deviceMapping[effectValue],
      timelineEntries[i].modifier
        ? deviceMapping[timelineEntries[i].modifier!]
        : []
    )
  );
  await sendToSerial(writable, ss);
};

*/

export default function App() {
  const [serialPort, setSerialPort] = React.useState<any>(undefined);

  return (
    <AuthProvider>
      <MantineProvider
        theme={{ colorScheme: "dark" }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications position="top-right" />
        <Routes>
          <Route
            path="/"
            element={
              <AppShell
                padding="xs"
                header={
                  <HeaderContent
                    serialPort={serialPort}
                    setSerialPort={setSerialPort}
                    links={[]}
                  />
                }
                footer={<FooterContent />}
                styles={(theme) => ({
                  main: {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.colors.gray[0],
                  },
                })}
              >
                <Outlet />
              </AppShell>
            }
          >
            <Route index element={<Home />} />
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <Projects />
                </PrivateRoute>
              }
            />
            <Route path="/browse" element={<Browse />} />
            <Route
              path="edit/:id"
              element={
                <PrivateRoute>
                  <Editor serialPort={serialPort} />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<p>404 Not Found</p>} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegistrationForm />} />
          </Route>
        </Routes>
      </MantineProvider>
    </AuthProvider>
  );
}
