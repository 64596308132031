import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";

const baseURL = "/api";

const useAxios = () => {
  const navigate = useNavigate();
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    let user = null;

    if (authTokens) {
      user = jwt_decode(authTokens.access);
    }

    const isExpired = user && dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    try {
      const response = await axios.post(`${baseURL}/token/refresh/`, {
        refresh: authTokens.refresh,
      });
      localStorage.setItem("authTokens", JSON.stringify(response.data));

      setAuthTokens(response.data);
      setUser(jwt_decode(response.data.access));

      req.headers.Authorization = `Bearer ${response.data.access}`;
      return req;
    } catch (err) {
      console.log(err);
      localStorage.removeItem("authTokens");
      return req;
    }
  });

  axiosInstance.interceptors.response.use(
    async (res) => {
      return res;
    },
    (error) => {
      console.log(error.response.config);
      if (
        error.response.status === 401 &&
        !error.response.config.url.includes("refresh")
      ) {
        notifications.show({
          title: "Not Authorized",
          color: "red",
          message: "Unable to perform this action",
          autoClose: 3000,
        });
        navigate("/login");
      }
      if (error.response.status === 400) {
        notifications.show({
          title: "Error",
          color: "red",
          message: "error.response.data['detail']",
          autoClose: 3000,
        });
      }
      if (error.response.status === 500) {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured when trying to perform this action",
          autoClose: 3000,
        });
      }
    }
  );

  return axiosInstance;
};
export default useAxios;
