import {
  Flex,
  Container,
  Timeline,
  Text,
  Badge,
  Button,
  Card,
  Group,
  ScrollArea,
  Slider,
  Grid,
  Paper,
  Tooltip,
  Modal,
  Box,
  LoadingOverlay,
  UnstyledButton,
  createStyles,
  Dialog,
  Divider,
  Alert,
} from "@mantine/core";
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";
import * as React from "react";
import "./App.css";
import {
  useDisclosure,
  useLocalStorage,
  useToggle,
  useDebouncedValue,
} from "@mantine/hooks";
import { EntryDeleteButton } from "./EntryDeleteButton";
import {
  ArrowAutofitHeight,
  Edit,
  Flask,
  LayoutList,
  PlayerPause,
  PlayerPlay,
  PlayerTrackNext,
  PlayerTrackPrev,
  AlertCircle,
} from "tabler-icons-react";
import { useParams } from "react-router-dom";
import useAxios from "./hooks/useAxios";
import { Project, TimelineEntry } from "./types/project";
import { useRecoilState } from "recoil";
import { navAtom, NavStatus } from "./state";
import { PresetModal } from "./components/PresetModal";
import { useUserPresets } from "./hooks/useUserPresets";
import { EffectSelector } from "./components/EffectSelector";
import { getReadableTimestamp } from "./utils/time";
import { getVideoUrl } from "./utils/media";
import { DeviceEffect } from "./types/device";
import { notifications } from "@mantine/notifications";
import { bitsToSerialString, createBitCode, sendToSerial } from "./App";
import { SessionWarningModal } from "./components/SessionWarningModal";
import { useProjectEffects } from "./hooks/useProjectEffects";
import { EntryEditorModal } from "./components/EntryEditorModal";

const useStyles = createStyles((theme) => ({
  presetButton: {
    backgroundColor: theme.colors.gray[9],
    "&:hover": {
      backgroundColor: theme.colors.gray[8],
    },
  },
  timelineEntry: {
    "&:hover": {
      backgroundColor: theme.colors.gray[8],
    },
  },
  selected: {
    backgroundColor: "#1c1c1c",
    boxShadow: "inset 0px 0px 0px 2px #111111",
  },
}));

interface EditorProps {
  serialPort: any;
}

export default function Editor(props: EditorProps) {
  const api = useAxios();
  const { id } = useParams();
  const { classes, cx } = useStyles();

  const player = React.useRef<ReactPlayer>(null);
  const timelineViewport = React.useRef<HTMLDivElement>(null);

  const [editModalOpened, editModalControls] = useDisclosure(false);

  const [nav, setNav] = useRecoilState(navAtom);
  const [loading, setLoading] = React.useState(true);
  const [timelineLoading, setTimelineLoading] = React.useState(true);
  const [project, setProject] = React.useState<Project | undefined>();
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [followTimeline, setFollowTimeline] = React.useState(true);
  const [playing, setPlaying] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [progress, setProgress] = React.useState({
    played: 0.0,
    playedSeconds: 0.0,
    loaded: 0.0,
    loadedSeconds: 0.0,
  });
  const [lastProcessed, setLastProcessed] = React.useState(-1.0);
  const [lastProcessedIndex, setLastProcessedIndex] = React.useState(0);
  const [timelineEntries, setTimelineEntries] = React.useState<TimelineEntry[]>(
    []
  );
  const [selected, setSelected] = React.useState<TimelineEntry[]>([]);
  const [shiftDown, setShiftDown] = React.useState(false);
  const [ctrlDown, setCtrlDown] = React.useState(false);

  const [debouncedTimeline] = useDebouncedValue(timelineEntries, 700);

  const [volume, setVolume] = useLocalStorage({
    key: "shmmr-volume",
    defaultValue: 0.75,
  });

  const [tickValue, toggleTickValue] = useToggle([
    { value: 0.1, label: "10ms" },
    { value: 1.0, label: "1s" },
    { value: 0.01, label: "1ms" },
  ]);
  const [previewMode, togglePreviewMode] = useToggle([false, true]);
  const [playbackRate, togglePlaybackRate] = useToggle([
    { value: 1.0, label: "1x" },
    { value: 0.75, label: "0.75x" },
    { value: 0.5, label: "0.5x" },
    { value: 0.25, label: "0.25x" },
    { value: 2.0, label: "2x" },
    { value: 1.75, label: "1.75x" },
    { value: 1.5, label: "1.5x" },
    { value: 1.25, label: "1.25x" },
  ]);

  const [presets] = useUserPresets(id ?? "");

  const deviceMapping = useProjectEffects(id);

  const [selectedEntry, setSelectedEntry] = React.useState<
    TimelineEntry | undefined
  >();
  const [entryEditorOpen, setEntryEditorOpen] = React.useState(false);

  React.useEffect(() => {
    if (!id) return;
    api
      .get(`/projects/${id}`)
      .then((response) => {
        setProject(response.data);
        setNav({
          showConnector: true,
          inEditor: true,
          title: `${response.data.name}`,
          sessionId: nav.sessionId,
        } as NavStatus);
      })
      .finally(() => {
        setLoading(false);
      });
    api
      .get(`projects/${id}/timeline`)
      .then((preset_resp) => {
        setTimelineEntries(preset_resp.data);
        setTimelineLoading(false);
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while fetching the timeline",
          autoClose: 3000,
        });
      });
  }, [id]);

  React.useEffect(() => {
    if (timelineLoading) return;
    console.log("Saving timeline to server...");
    api
      .post(`/projects/${id}/timeline/save`, {
        entries: debouncedTimeline,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("Done saving timeline to server!");
      });
  }, [debouncedTimeline]);

  React.useMemo(() => {
    if (!timelineViewport.current) return;
    if (!followTimeline) return;
    let pos = currentIndex / timelineEntries.length;

    timelineViewport.current.scrollTo({
      top: timelineViewport.current.scrollHeight * pos,
      behavior: "smooth",
    });
  }, [currentIndex, timelineEntries, followTimeline]);

  React.useEffect(() => {
    if (dragging) return;

    const playEligibleEffects = async () => {
      if (progress.playedSeconds - lastProcessed > 0.01) {
        setLastProcessed(progress.playedSeconds);
        try {
          for (let i = lastProcessedIndex; i < timelineEntries.length; i++) {
            if (timelineEntries[i].timestamp > lastProcessed) {
              if (timelineEntries[i].timestamp <= progress.playedSeconds) {
                if (props.serialPort === undefined) return;
                await sendToSerial(
                  props.serialPort,
                  bitsToSerialString(
                    createBitCode(
                      deviceMapping[timelineEntries[i].effect],
                      timelineEntries[i].modifier
                        ? deviceMapping[timelineEntries[i].modifier!]
                        : []
                    )
                  )
                );
              } else {
                setLastProcessedIndex(i === 0 ? 0 : i - 1);
                setCurrentIndex(i === 0 ? 0 : i - 1);
                break;
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (progress.playedSeconds < lastProcessed) {
        setLastProcessed(progress.playedSeconds);
      }
    };

    playEligibleEffects();
    /*
    setCurrentIndex(
      timelineEntries.filter((ent) => ent.timestamp <= progress.playedSeconds)
        .length - 1
    );
    */
  }, [
    dragging,
    progress.playedSeconds,
    lastProcessed,
    props.serialPort,
    timelineEntries,
  ]);

  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      switch (event.key) {
        case "Shift":
          setShiftDown(true);
          break;
        case "Meta":
          setCtrlDown(true);
          break;
        default:
          break;
      }
      if (event.keyCode === 90 && event.ctrlKey) {
        event.preventDefault();
        //undoAction();
      }
    });
    document.addEventListener("keyup", function (event) {
      switch (event.key) {
        case "Shift":
          setShiftDown(false);
          break;
        case "Meta":
          setCtrlDown(false);
          break;
        default:
          break;
      }
    });
  }, []);

  if (!project) {
    return null;
  }

  const addToTimeline = async (
    effect: DeviceEffect,
    modifier?: DeviceEffect
  ) => {
    if (previewMode) {
      // Preview mode enabled, just test
      // TODO: Add this to separate function
      if (props.serialPort === undefined) return;
      await sendToSerial(
        props.serialPort,
        bitsToSerialString(
          createBitCode(
            deviceMapping[effect.id],
            modifier ? deviceMapping[modifier!.id] : []
          )
        )
      );
      return;
    }

    const newEntry: TimelineEntry = {
      ref_id: uuidv4(),
      timestamp: progress.playedSeconds,
      effect: effect.id,
      effect_name: effect.name,
      modifier: modifier?.id,
      modifier_name: modifier?.name,
    };

    let newTimeline = [...timelineEntries, newEntry];

    newTimeline = newTimeline.sort((a, b) => {
      return a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0;
    });

    setTimelineEntries(newTimeline);
  };

  const updateTimeline = (entry: TimelineEntry) => {
    let newTimeline = timelineEntries.filter(
      (ent) => entry.ref_id !== ent.ref_id
    );
    newTimeline.push(entry);
    newTimeline = [...newTimeline].sort((a, b) => {
      return a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0;
    });
    setTimelineEntries(newTimeline);
  };

  const removeFromTimeline = (entry: TimelineEntry) => {
    const newTimeline = timelineEntries.filter(
      (ent) => entry.ref_id !== ent.ref_id
    );
    setTimelineEntries(newTimeline);
  };

  return (
    <Flex
      gap="xs"
      justify="flex-start"
      align="flex-start"
      direction="row"
      wrap="wrap"
    >
      <LoadingOverlay visible={loading} />
      <SessionWarningModal project_id={id ?? ""} />

      {selectedEntry && (
        <EntryEditorModal
          opened={entryEditorOpen}
          entry={selectedEntry}
          timestamp={progress.playedSeconds}
          onClose={() => {
            setSelectedEntry(undefined);
          }}
          onEdit={(updatedEntry) => {
            console.log("updating...");
            updateTimeline(updatedEntry);
            setSelectedEntry(undefined);
          }}
        />
      )}

      <Container
        miw={300}
        p={0}
        fluid
        style={{ flexGrow: 1 }}
        maw="calc(100% - 370px);"
        h="calc(100vh - 100px)"
      >
        <Flex direction="column" h="100%">
          <Box w="100%" h="100%" style={{ position: "relative" }}>
            <ReactPlayer
              width="100%"
              height="100%"
              style={{
                flex: "1 1 auto",
              }}
              playing={playing}
              ref={player}
              url={getVideoUrl(project.media_url, project.media_type)}
              progressInterval={100}
              controls={false}
              onProgress={(prog) => setProgress(prog)}
              playbackRate={playbackRate.value}
              volume={volume}
              onPlay={() => {
                setPlaying(true);
              }}
              onPause={() => {
                setPlaying(false);
              }}
            />
          </Box>
          <div>
            <Paper
              style={{ width: "100%", padding: "15px", margin: "0px" }}
              radius={0}
            >
              <Grid w="100%">
                <Grid.Col
                  span={3}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <div style={{ width: "100%" }}>
                    <Slider
                      label="Volume"
                      showLabelOnHover={false}
                      value={volume * 100}
                      onChange={(val) => setVolume(val / 100)}
                    />
                  </div>
                </Grid.Col>
                <Grid.Col
                  span={7}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Group position="right" mr="10px">
                    <Tooltip label="Adjusts time granularity of previous and next buttons">
                      <Button
                        w="50px"
                        radius="xl"
                        pl="5px"
                        pr="5px"
                        onClick={() => {
                          toggleTickValue();
                        }}
                        color="gray"
                        size="xs"
                      >
                        {tickValue.label}
                      </Button>
                    </Tooltip>
                    <Tooltip label="Adjusts time granularity of previous and next buttons">
                      <Button
                        w="50px"
                        radius="xl"
                        pl="5px"
                        pr="5px"
                        onClick={() => {
                          togglePlaybackRate();
                        }}
                        color="gray"
                        size="xs"
                      >
                        {playbackRate.label}
                      </Button>
                    </Tooltip>
                  </Group>
                  <Button
                    p={6}
                    onClick={() => {
                      setLastProcessed(
                        progress.playedSeconds - tickValue.value
                      );
                      setLastProcessedIndex(0);
                      player.current!.seekTo(
                        progress.playedSeconds - tickValue.value
                      );
                    }}
                    color="cyan"
                    radius="xl"
                  >
                    {<PlayerTrackPrev />}
                  </Button>
                  <Button
                    p={12}
                    ml={3}
                    mr={3}
                    onClick={() => {
                      setPlaying(!playing);
                    }}
                    color="green"
                    radius="xl"
                    size="lg"
                  >
                    {playing ? <PlayerPause /> : <PlayerPlay />}
                  </Button>
                  <Button
                    p={6}
                    onClick={() => {
                      setLastProcessed(
                        progress.playedSeconds + tickValue.value
                      );
                      setLastProcessedIndex(0);
                      player.current!.seekTo(
                        progress.playedSeconds + tickValue.value
                      );
                    }}
                    color="cyan"
                    radius="xl"
                  >
                    {<PlayerTrackNext />}
                  </Button>

                  <Badge
                    ml={5}
                    color="gray"
                    display="inline-block"
                    className="monoSpace"
                  >
                    {getReadableTimestamp(progress.playedSeconds)}
                  </Badge>
                </Grid.Col>
                <Grid.Col
                  span={2}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Tooltip label="Preview Mode activates effect instead of adding it into the timeline">
                    <Button
                      variant={previewMode ? "filled" : "outline"}
                      onClick={() => {
                        togglePreviewMode();
                      }}
                    >
                      <Flask size="1rem" />
                    </Button>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </Paper>
            {player.current && (
              <Slider
                marks={timelineEntries.map((ent) => {
                  return { value: ent.timestamp };
                })}
                min={0.0}
                max={player.current!.getDuration()}
                onChange={(val) => {
                  setDragging(true);
                  setProgress({ ...progress, playedSeconds: val });
                  player.current!.seekTo(val, "seconds");
                }}
                onChangeEnd={(val) => {
                  setLastProcessed(val);
                  setLastProcessedIndex(0);
                  setDragging(false);
                }}
                value={progress.playedSeconds}
                label={(num) => {
                  return (
                    <Text className="monoSpace">
                      {getReadableTimestamp(num)}
                    </Text>
                  );
                }}
                mt="-10px"
              />
            )}
          </div>

          <Modal
            size="xl"
            fullScreen
            opened={editModalOpened}
            onClose={editModalControls.close}
            title="Preset Editor"
            centered
          >
            <PresetModal project_id={id ?? ""} serialPort={props.serialPort} />
          </Modal>

          <Card
            withBorder
            shadow="sm"
            radius="md"
            mt="5px"
            mr="0px"
            mih="0"
            style={{ flex: "0 0 auto" }}
          >
            <Card.Section withBorder inheritPadding py={5}>
              <Group position="apart">
                <EffectSelector
                  testable={false}
                  previewMode={previewMode}
                  size="xs"
                  onSelect={addToTimeline}
                />
                <Button
                  size="xs"
                  leftIcon={<Edit size="0.8rem" />}
                  variant={followTimeline ? undefined : "outline"}
                  onClick={() => editModalControls.open()}
                >
                  Presets
                </Button>
              </Group>
            </Card.Section>

            <Card.Section p={5} bg="#1A1B1E" mih="0">
              <Flex wrap="wrap" direction="row" justify="center" mih="0px">
                {presets.length > 0 ? (
                  presets.map((preset) => {
                    return (
                      <UnstyledButton
                        key={preset.id}
                        onClick={() => {
                          const effect: DeviceEffect = {
                            id: preset.effect,
                            name: preset.effect_name,
                            device: "",
                            modifier: false,
                            data: "",
                          };
                          const modifier: DeviceEffect | undefined =
                            preset.modifier && preset.modifier_name
                              ? {
                                  id: preset.modifier,
                                  name: preset.modifier_name,
                                  device: "",
                                  modifier: true,
                                  data: "",
                                }
                              : undefined;

                          addToTimeline(effect, modifier);
                        }}
                      >
                        <Paper
                          p="5px"
                          miw="12rem"
                          maw="25%"
                          mih="50px"
                          radius={0}
                          className={classes.presetButton}
                          style={{
                            flexGrow: 1,
                          }}
                          key={preset.id}
                        >
                          <Text size="xs">{preset.effect_name}</Text>
                          <Text size="xs" color="dimmed">
                            {preset.modifier_name}
                          </Text>
                        </Paper>
                      </UnstyledButton>
                    );
                  })
                ) : (
                  <Text c="dimmed">
                    You have no presets for this project. Create some using the
                    preset editor!
                  </Text>
                )}
              </Flex>
            </Card.Section>
          </Card>
        </Flex>
      </Container>

      <Container miw={350} maw={400} m={0} p={0} fluid style={{ flexGrow: 1 }}>
        <Card withBorder shadow="sm" radius="md">
          <Card.Section withBorder inheritPadding py="xs">
            <Group position="apart">
              <Text weight={400}>
                <LayoutList size="0.5rem" /> Effects Timeline
              </Text>
              <Tooltip label="Automatically scrolls to show next effect in timeline">
                <Button
                  compact
                  size="xs"
                  leftIcon={<ArrowAutofitHeight size="1rem" />}
                  variant={followTimeline ? undefined : "outline"}
                  onClick={() => setFollowTimeline(!followTimeline)}
                >
                  Follow
                </Button>
              </Tooltip>
            </Group>
          </Card.Section>

          <Card.Section
            withBorder
            inheritPadding
            pl={7}
            pr={0}
            py="xs"
            style={{ flexGrow: 1, userSelect: "none" }}
          >
            <Dialog
              opened={selected.length > 0}
              onClose={() => setSelected([])}
              withCloseButton
              position={{
                top: 0,
                right: 0,
              }}
            >
              <Text size="sm" mb="xs" weight={500}>
                Multi-Select Actions
              </Text>
              <Divider mt={10} mb={10} />
              <Group>
                <Text>Bulk actions coming soon!</Text>
              </Group>
            </Dialog>

            <ScrollArea
              h="calc(100vh - 165px)"
              type="always"
              scrollbarSize={12}
              viewportRef={timelineViewport}
            >
              {timelineEntries.length >= 500 && (
                <Alert
                  mb={10}
                  icon={<AlertCircle size="1rem" />}
                  title="Large Project Detected"
                  color="red"
                >
                  Timeline reduced to increase playback performance.
                </Alert>
              )}
              <Timeline
                active={
                  timelineEntries.length < 500 || !playing ? currentIndex : 0
                }
                bulletSize={20}
                lineWidth={2}
              >
                {timelineEntries.map((entry, i) => {
                  if (timelineEntries.length > 0) {
                    if (i < currentIndex) {
                      return null;
                    }
                    if (i > currentIndex + 5) {
                      return null;
                    }
                  }

                  const currSelected =
                    selected.filter((sl) => {
                      return sl.ref_id === entry.ref_id;
                    }).length > 0;

                  return (
                    <Timeline.Item
                      key={i}
                      pt={5}
                      pb={5}
                      className={cx(
                        classes.timelineEntry,
                        currSelected && classes.selected
                      )}
                      onClick={() => {
                        if (shiftDown) {
                          if (selected.length === 0) {
                            // None selected, first click
                            setSelected([entry]);
                            console.log(entry);
                          } else {
                            // 2nd click
                            const allSelected = [entry, ...selected];

                            const minSelected = allSelected.reduce(function (
                              prev,
                              curr
                            ) {
                              return prev.timestamp <= curr.timestamp
                                ? prev
                                : curr;
                            });

                            const maxSelected = allSelected.reduce(function (
                              prev,
                              curr
                            ) {
                              return prev.timestamp >= curr.timestamp
                                ? prev
                                : curr;
                            });

                            setSelected(
                              timelineEntries.filter((te) => {
                                return (
                                  te.timestamp >= minSelected.timestamp &&
                                  te.timestamp <= maxSelected.timestamp
                                );
                              })
                            );
                          }
                        } else if (ctrlDown) {
                          if (currSelected) {
                            // Selected already, toggle
                            console.log(selected);
                            console.log(
                              selected.filter((sl) => {
                                return sl.ref_id !== entry.ref_id;
                              })
                            );

                            setSelected(
                              selected.filter((sl) => {
                                return sl.ref_id !== entry.ref_id;
                              })
                            );
                            return;
                          } else {
                            // Not selected
                            setSelected([...selected, entry]);
                            return;
                          }
                        } else {
                          setSelected([]);
                        }
                      }}
                      title={
                        <>
                          <Flex>
                            <Container p={0} style={{ flexGrow: 1 }}>
                              <Badge
                                variant={
                                  i === currentIndex ? "filled" : "light"
                                }
                                onClick={() => {
                                  setSelectedEntry(entry);
                                  setEntryEditorOpen(true);
                                }}
                              >
                                {entry.effect_name}
                              </Badge>
                              <br />
                              {entry.modifier && (
                                <Badge mt={3} variant="outline">
                                  {entry.modifier_name}
                                </Badge>
                              )}
                            </Container>
                            <Container>
                              <Text
                                size="xs"
                                mt={0}
                                onClick={() => {
                                  player.current!.seekTo(
                                    entry.timestamp,
                                    "seconds"
                                  );
                                }}
                              >
                                {getReadableTimestamp(entry.timestamp)}
                              </Text>
                            </Container>
                          </Flex>
                        </>
                      }
                      color="blue.9"
                      bullet={
                        <EntryDeleteButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this from the timeline?"
                              ) === true
                            ) {
                              removeFromTimeline(entry);
                            }
                          }}
                        />
                      }
                    ></Timeline.Item>
                  );
                })}
              </Timeline>
            </ScrollArea>
          </Card.Section>
        </Card>
      </Container>
    </Flex>
  );
}
