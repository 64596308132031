import {
  createStyles,
  Header,
  Container,
  Group,
  Button,
  Burger,
  rem,
  Title,
  Text,
  UnstyledButton,
  Avatar,
  Menu,
  Modal,
  Anchor,
} from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import IonIcon from "@reacticons/ionicons";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  PlugConnected,
  PlugConnectedX,
  ChevronDown,
  UserCircle,
} from "tabler-icons-react";
import { connectSerial, disconnectSerial } from "../../App";
import { navAtom, userAtom } from "../../state";
import * as React from "react";
import AuthContext from "../../context/AuthContext";
import useAxios from "../../hooks/useAxios";
import { CreatorModal } from "../CreatorModal";
import { TutorialModal } from "../TutorialModal";
import { NewsModal } from "../NewsModal";

const HEADER_HEIGHT = rem(45);

const useStyles = createStyles((theme) => ({
  header: {
    borderBottom: "0px",
  },

  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(6)} ${rem(8)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

interface HeaderActionProps {
  links: {
    link: string;
    label: string;
    links: { link: string; label: string }[];
  }[];
  serialPort: any;
  setSerialPort: any;
}

export function HeaderContent({
  links,
  serialPort,
  setSerialPort,
}: HeaderActionProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const [creatorOpened, creatorCallbacks] = useDisclosure(false);
  const userSession = useRecoilValue(userAtom);
  const auth = React.useContext(AuthContext);
  const [user, setUser] = useRecoilState(userAtom);
  const nav = useRecoilValue(navAtom);

  const api = useAxios();

  const [viewedTutorial, setViewedTutorial] = useLocalStorage({
    key: "shmmr-tutorial",
    defaultValue: 0,
  });

  const [viewedNews, setViewedNews] = useLocalStorage({
    key: "shmmr-news",
    defaultValue: 0,
  });

  React.useEffect(() => {
    auth.user &&
      api
        .get("/session")
        .then((response) => {
          setUser(response.data);
        })
        .catch((err) => {
          console.log(err);
          // TODO: NOTIFY
        });
  }, [auth.user]);

  return (
    <Header height={HEADER_HEIGHT} className={classes.header}>
      <Container className={classes.inner} fluid>
        <Group>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />
          <Title order={3} size="sm">
            <IonIcon name="sparkles" /> Shmmr
          </Title>
          <Text>{nav.title}</Text>
        </Group>
        {userSession && (
          <Group spacing={5} className={classes.links}>
            <Button
              variant="subtle"
              className={classes.link}
              onClick={() => {
                navigate("/projects");
              }}
              leftIcon={<IonIcon name="download" />}
            >
              My Projects
            </Button>
            {
              <Button
                variant="subtle"
                className={classes.link}
                onClick={() => {
                  navigate("/browse");
                }}
                leftIcon={<IonIcon name="file-tray-full" />}
              >
                Browse
              </Button>
            }
            {!nav.inEditor && (
              <Button
                variant="light"
                className={classes.link}
                onClick={creatorCallbacks.open}
                leftIcon={<IonIcon name="add-outline" />}
              >
                New
              </Button>
            )}
          </Group>
        )}
        {nav.showConnector &&
          (serialPort === undefined ? (
            <Button
              compact
              color="green"
              leftIcon={<PlugConnected size={18} />}
              variant="light"
              onClick={() => connectSerial(setSerialPort)}
              radius="sm"
            >
              <p>Connect to Device</p>
            </Button>
          ) : (
            <Button
              compact
              color="green"
              leftIcon={<PlugConnectedX size={18} />}
              radius="sm"
              variant="filled"
              onClick={() => disconnectSerial(serialPort, setSerialPort)}
            >
              <p>Disconnect from Device</p>
            </Button>
          ))}
        {auth.user && user && (
          <Group>
            <Group>
              {viewedNews >= 0 && (
                <Button
                  variant="light"
                  size="xs"
                  leftIcon={<IonIcon name="newspaper-outline" />}
                  onClick={() => {
                    setViewedNews(0);
                  }}
                >
                  News
                </Button>
              )}
              {viewedTutorial >= 0 && (
                <Button
                  variant="light"
                  size="xs"
                  leftIcon={<IonIcon name="help-circle-outline" />}
                  onClick={() => {
                    setViewedTutorial(0);
                  }}
                >
                  Tutorial
                </Button>
              )}
              <Anchor
                href="https://beta.shmmr.app/static/ShmmrQuickStart.pdf"
                target="_blank"
              >
                <Button
                  variant="light"
                  size="xs"
                  leftIcon={<IonIcon name="help-circle-outline" />}
                >
                  User Guide
                </Button>
              </Anchor>
            </Group>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <UnstyledButton>
                  <Group spacing={7}>
                    <Avatar radius="xl">
                      <UserCircle />
                    </Avatar>
                    <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                      {user && user["username"]}
                    </Text>
                    <ChevronDown size={rem(12)} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    auth.logoutUser();
                  }}
                >
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        )}

        <Modal
          opened={creatorOpened}
          onClose={creatorCallbacks.close}
          title="Create New Project"
          centered
        >
          <CreatorModal close={creatorCallbacks.close} />
        </Modal>
        <NewsModal />
        <TutorialModal />
      </Container>
    </Header>
  );
}
