import { Modal, Group } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import ReactPlayer from "react-player";

export const TutorialModal = () => {
  const tutorial_date = 1690371666;
  const tutorial_video = "https://www.youtube.com/watch?v=KPIMptDkA40";
  const auth = useContext(AuthContext);
  const [viewedTutorial, setViewedTutorial] = useLocalStorage({
    key: "shmmr-tutorial",
    defaultValue: 0,
  });

  if (!auth.user) return null;
  if (viewedTutorial > tutorial_date) return null;

  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);

  return (
    <>
      <Modal
        opened={viewedTutorial <= tutorial_date}
        onClose={() => {
          setViewedTutorial(secondsSinceEpoch);
        }}
        title="Shmmr - Get Started"
        size={"xl"}
      >
        <Group position="center">
          <ReactPlayer
            width="100%"
            height="500px"
            style={{
              flex: "1 1 auto",
            }}
            url={tutorial_video}
            controls={true}
          />
        </Group>
      </Modal>
    </>
  );
};
