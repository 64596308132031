import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Device, DeviceEffect } from "../types/device";
import { deviceAtom, deviceEffectAtom } from "../state";
import useAxios from "./useAxios";

export const useDevices = (
  deviceID: string | null | undefined
): [Device[], DeviceEffect[]] => {
  const api = useAxios();
  const [devices, setDevices] = useRecoilState(deviceAtom);
  const [deviceEffects, setDeviceEffects] = useRecoilState(deviceEffectAtom);

  useEffect(() => {
    api
      .get(`/devices`)
      .then((preset_resp) => {
        setDevices(preset_resp.data);
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while fetching devices",
          autoClose: 3000,
        });
      });
  }, []);

  useEffect(() => {
    if (!deviceID && devices.length === 0) {
      setDeviceEffects([]);
      return;
    }

    api
      .get(`/devices/${deviceID ? deviceID : devices[0].id}`)
      .then((deviceEffects) => {
        setDeviceEffects(deviceEffects.data.effects);
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while fetching device effects",
          autoClose: 3000,
        });
      });
  }, [devices, deviceID]);

  return [devices, deviceEffects];
};
