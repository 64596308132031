import * as React from 'react'
import { Trash, Bulb } from 'tabler-icons-react';


export interface EntryDeleteButtonProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function EntryDeleteButton( props: EntryDeleteButtonProps ) {
    const [hovered, setHovered] = React.useState(false);

    return <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={props.onClick}>
        {hovered ?  <Trash size={14} /> : <Bulb size={14} />}
    </div>
}