import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export const Home = () => {
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    // redirect to home if already logged in
    if (auth.user) {
      navigate("/projects");
    } else {
      navigate("/login");
    }
  }, [auth.user]);

  return <p>Welcome to Shmmr</p>;
};
