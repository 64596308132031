import * as React from "react";
import {
  Card,
  Text,
  Button,
  Group,
  Flex,
  Container,
  Input,
  Divider,
  Grid,
  Loader,
  Modal,
  BackgroundImage,
  Box,
} from "@mantine/core";
import { Search, Pencil, Trash } from "tabler-icons-react";
import useAxios from "../../hooks/useAxios";
import { notifications } from "@mantine/notifications";
import { Project } from "../../types/project";
import { useNavigate } from "react-router-dom";
import { navAtom, NavStatus } from "../../state";
import { useRecoilState } from "recoil";
import { useDisclosure } from "@mantine/hooks";

export default function Projects() {
  const api = useAxios();
  const navigate = useNavigate();
  const [nav, setNav] = useRecoilState(navAtom);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [dataVersion, setDataVersion] = React.useState(0);

  const [deleteConfirmationOpened, deleteConfirmationCallback] =
    useDisclosure(false);
  const [idToDelete, setIDToDelete] = React.useState("");

  React.useEffect(() => {
    setNav({
      showConnector: false,
      inEditor: false,
      title: "",
      sessionId: nav.sessionId,
    } as NavStatus);

    api
      .get("/projects")
      .then((projects) => {
        setProjects(projects.data);
      })
      .catch((err) => {
        notifications.show({
          title: "Error when fetching projects",
          color: "red",
          message: err.message,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataVersion]);

  const deleteProject = (project_id: string) => {
    api
      .delete(`/projects/${project_id}/delete`)
      .then((response) => {})
      .then(() => {
        notifications.show({
          title: "Deleted",
          color: "green",
          message: "Project successfully deleted",
          autoClose: 3000,
        });
        deleteConfirmationCallback.close();
      })
      .catch((err) => {
        notifications.show({
          title: "Error when deleting project",
          color: "red",
          message: err,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setDataVersion(dataVersion + 1);
      });
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Flex
      gap="xs"
      justify="flex-start"
      align="flex-start"
      direction="row"
      wrap="wrap"
    >
      <Container w="100%">
        <Text size="xl" mb="sm">
          My Projects
        </Text>
        <Input
          icon={<Search />}
          placeholder="Search projects..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Divider my="sm" variant="dashed" />

        <Grid>
          {loading && <Loader />}
          {!loading && filteredProjects.length === 0 ? (
            search.length === 0 ? (
              <p>You have no projects. Create one!</p>
            ) : (
              <p>Your search filter returned no results</p>
            )
          ) : (
            filteredProjects.map((project) => {
              return (
                <Grid.Col span={4} key={project.id}>
                  <Card>
                    <Card.Section>
                      <BackgroundImage
                        src={`https://img.youtube.com/vi/${project.media_url}/sddefault.jpg`}
                        radius="sm"
                        mih="120px"
                      >
                        <Box bg={"rgba(0,0,0,0.5)"} w="100%" mt="0px" p="sm">
                          <Text color="#fff" fz="sm">
                            {project.name}
                          </Text>
                        </Box>
                      </BackgroundImage>
                    </Card.Section>
                    <Group position="center" mt="md">
                      <Button
                        variant="light"
                        color="red"
                        radius="md"
                        size="xs"
                        onClick={() => {
                          setIDToDelete(project.id);
                          deleteConfirmationCallback.open();
                        }}
                      >
                        <Trash />
                      </Button>
                      <Button
                        leftIcon={<Pencil />}
                        color="blue"
                        radius="md"
                        size="xs"
                        onClick={() => {
                          navigate(`/edit/${project.id}`);
                        }}
                      >
                        Edit
                      </Button>
                      {/*
                                              <Button
                        color="blue"
                        radius="md"
                        size="xs"
                        onClick={() => {
                          navigate(`/play/${project.id}`);
                        }}
                      >
                        <PlayerPlay />
                      </Button>
                        */}

                      <Modal
                        opened={deleteConfirmationOpened}
                        onClose={deleteConfirmationCallback.close}
                        title="Delete Confirmation"
                        centered
                      >
                        <Text>Are you sure you want to delete this?</Text>
                        <Text c="dimmed">This action cannot be undone.</Text>
                        <Divider mt="md" />
                        <Group position="right" mt="md">
                          <Button
                            color="green"
                            onClick={() => {
                              deleteProject(idToDelete);
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            color="red"
                            onClick={deleteConfirmationCallback.close}
                          >
                            Cancel
                          </Button>
                        </Group>
                      </Modal>
                    </Group>
                  </Card>
                </Grid.Col>
              );
            })
          )}
        </Grid>
      </Container>
    </Flex>
  );
}
