import {
  Modal,
  Group,
  Spoiler,
  Text,
  Drawer,
  Stack,
  Paper,
  ScrollArea,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import useAxios from "../hooks/useAxios";
import React from "react";
import { NewsEntry } from "../types/news";
import { notifications } from "@mantine/notifications";

export const NewsModal = () => {
  const news_date = 0;
  const auth = useContext(AuthContext);
  const [viewedNews, setViewedNews] = useLocalStorage({
    key: "shmmr-news",
    defaultValue: 0,
  });
  const [loading, setLoading] = React.useState(true);
  const [newsEntries, setNewsEntries] = React.useState<NewsEntry[]>([]);

  const api = useAxios();

  React.useEffect(() => {
    api
      .get("/news")
      .then((newsentries) => {
        setNewsEntries(newsentries.data);
        if (newsEntries.length > 0) {
          if (newsEntries[0].created.getTime() / 1000 > viewedNews) {
            setViewedNews(newsEntries[0].created.getTime() / 1000);
          }
        }
      })
      .catch((err) => {
        notifications.show({
          title: "Error when fetching news data",
          color: "red",
          message: err.message,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!auth.user) return null;
  if (viewedNews > news_date) return null;

  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);

  return (
    <>
      <Drawer
        opened={viewedNews <= news_date}
        onClose={() => {
          setViewedNews(secondsSinceEpoch);
        }}
        title="Shmmr News"
        size={"xl"}
      >
        <ScrollArea>
          <Group position="center">
            {newsEntries.map((entry) => {
              return (
                <Paper shadow="xs" color="gray" p="sm" withBorder>
                  <Spoiler
                    maxHeight={200}
                    showLabel="Show more"
                    hideLabel="Hide"
                  >
                    <Stack>
                      <Text fz="lg">{entry.title}</Text>
                      <Text fz="sm">{`${String(entry.created)} - ${
                        entry.author
                      }`}</Text>
                      <Text style={{ whiteSpace: "pre-wrap" }}>
                        {entry.body}
                      </Text>
                    </Stack>
                  </Spoiler>
                </Paper>
              );
            })}
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
};
