import { Anchor, Button, Footer, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export const FooterContent = () => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Footer height={30}>
      <Text span pl="15px" h={30} pt={2} size="xs">
        Shmmr.app · Version 0.1.1-beta
      </Text>
      <Button size="xs" color="gray" compact variant="subtle" onClick={open}>
        Open Source Credits
      </Button>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title="Open Source Credits"
      >
        <Text size="md">
          Shmmr would not be possible without open source software.
        </Text>
        <Text size="md">
          The team would like to thank and acknowledge the following projects:
        </Text>
        <Stack mt={20}>
          <Text size="sm">
            <Anchor href="https://github.com/danielweidman/pixmob-ir-reverse-engineering">
              PixMob IR (and RF!) Reverse Engineering Project
            </Anchor>
          </Text>
          <Text size="sm">
            <Anchor href="https://github.com/Arduino-IRremote/Arduino-IRremote">
              Arduino-IRremote
            </Anchor>
          </Text>
          <Text size="sm">
            <Anchor href="https://github.com/earlephilhower/arduino-pico">
              Arduino-Pico
            </Anchor>
          </Text>
          <Text size="sm">
            <Anchor href="https://github.com/mantinedev/mantine">
              Mantine
            </Anchor>
          </Text>
        </Stack>
      </Modal>
    </Footer>
  );
};
