import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { PresetEntry } from "../types/project";
import { presetEntryAtom, presetRevisionAtom } from "../state";
import useAxios from "./useAxios";

export const useUserPresets = (
  project_id: string
): [PresetEntry[], (revisionTimestamp: number) => void] => {
  const [presets, setPresets] = useRecoilState<PresetEntry[]>(presetEntryAtom);
  const [presetRevision, setPresetRevision] =
    useRecoilState(presetRevisionAtom);
  const api = useAxios();

  useEffect(() => {
    api
      .get(`projects/${project_id}/presets`)
      .then((preset_resp) => {
        setPresets(preset_resp.data);
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while fetching presets",
          autoClose: 3000,
        });
      });
  }, [presetRevision, project_id]);

  const setRevision = (revisionTimestamp: number) => {
    setPresetRevision(revisionTimestamp);
  };

  return [presets, setRevision];
};
