import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { deviceMappingAtom } from "../state";
import useAxios from "./useAxios";

export const useProjectEffects = (
  projectID: string | null | undefined
): Record<string, number[]> => {
  const api = useAxios();
  const [deviceMapping, setDeviceMapping] = useRecoilState(deviceMappingAtom);

  useEffect(() => {
    if (!projectID) return;
    api
      .get(`/projects/${projectID}/effects`)
      .then((resp) => {
        setDeviceMapping(resp.data["effects"]);
      })
      .catch((err) => {
        notifications.show({
          title: "Error",
          color: "red",
          message: "An error occured while fetching device mappings",
          autoClose: 3000,
        });
      });
  }, [projectID]);

  return deviceMapping;
};
