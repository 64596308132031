import { atom } from 'recoil';
import { PresetEntry } from '../types/project';

const presetEntryAtom = atom<PresetEntry[]>({
    key: 'presetentry',
    default: []
});

const presetRevisionAtom = atom<number>({
    key: 'presetRevisionAtom',
    default: 0
});

export { presetEntryAtom, presetRevisionAtom };