import { Group, Button, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import useAxios from "../hooks/useAxios";

export interface CreatorModalProps {
  close: () => void;
}

export const CreatorModal = (props: CreatorModalProps) => {
  const api = useAxios();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      media_url: "",
      media_type: "YT",
    },
    validate: {
      name: (value) =>
        value.length < 2
          ? "Project Name must have at least 2 characters"
          : null,
      description: (value) => null,
      media_url: (value) =>
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/.test(
          value
        )
          ? null
          : "Invalid YouTube URL",
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        api
          .post("/projects/create", values)
          .then((res) => {
            if (res.data.success) {
              props.close();
              navigate(`/edit/${res.data["project_id"]}`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })}
    >
      <TextInput
        withAsterisk
        label="Name"
        placeholder="Project Name"
        {...form.getInputProps("name")}
      />
      <Textarea
        label="Description"
        placeholder="Project Description"
        {...form.getInputProps("description")}
      />
      <TextInput
        withAsterisk
        label="Media URL"
        placeholder="https://www.youtube.com/watch?v=RxNmdMXuSCk"
        {...form.getInputProps("media_url")}
      />
      <Group position="right" mt="md">
        <Button type="submit">Create</Button>
      </Group>
    </form>
  );
};
